import { css, jsx as e, ThemeProvider, Global } from "@emotion/react";
import { CookiesProvider } from "react-cookie";
import { IdentityContextProvider } from "react-netlify-identity";
import emotionNormalize from "emotion-normalize";

import { StateProvider } from "./src/GlobalContext";
import { theme } from "./src/Theme";

function anchorScroll(location) {
  // Check for location so build does not fail
  if (location && location.hash) {
    setTimeout(() => {
      document
        .querySelector(`${location.hash}`)
        .scrollIntoView({ behavior: "smooth", block: "center" });
    }, 1000);
  }
}

export const onRouteUpdate = ({ location }) => {
  anchorScroll(location);
  return true;
};

export const shouldUpdateScroll = ({
  routerProps: { location },
  getSavedScrollPosition,
}) => {
  anchorScroll(location);
  return true;
};

const url = "https://desma-campus.netlify.app";

export const wrapRootElement = ({ element }) => {
  return e(
    IdentityContextProvider,
    { url },
    e(
      CookiesProvider,
      {},
      e(
        ThemeProvider,
        { theme },
        e(
          StateProvider,
          {},
          e(Global, {
            styles: css`
              ${emotionNormalize}
              * {
                box-sizing: border-box;
              }
              @font-face {
                font-family: TitilliumWeb_Light;
                src: url("/fonts/Titillium_Web/TitilliumWeb-Light.ttf");
              }

              @font-face {
                font-family: TitilliumWeb_Regular;
                src: url("/fonts/Titillium_Web/TitilliumWeb-Regular.ttf");
              }

              @font-face {
                font-family: TitilliumWeb_Medium;
                src: url("/fonts/Titillium_Web/TitilliumWeb-SemiBold.ttf");
              }

              @font-face {
                font-family: TitilliumWeb_Bold;
                src: url("/fonts/Titillium_Web/TitilliumWeb-Bold.ttf");
              }

              @font-face {
                font-family: Caveat;
                src: url("/fonts/Caveat-VariableFont_wght.ttf");
              }

              body {
                margin: 0px;
                padding: 0px;
              }
            `,
          }),
          element
        )
      )
    )
  );
};
