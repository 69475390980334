import { createContext, useState } from "react";
import { html } from "./htm";

export const Context = createContext([
  { showLogin: false, events: [] },
  () => {},
  {},
]);

export const StateProvider = ({ children }) => {
  const [globalState, setGlobalState] = useState({
    showLogin: false,
  });

  return html`<${Context.Provider}
    value=${[globalState, setGlobalState]}
  >
    ${children}
  <//>`;
};
